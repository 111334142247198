import { defineStore } from "pinia";
import { useLocale } from "vuetify";
import type {Locale} from "@intlify/core-base";

export default defineStore("ct:region", () => {
  const { locale, locales, setLocale, t } = useI18n();
  const runtimeConfig = useRuntimeConfig();
  const authStore = useAuthStore();
  const api = useApi();
  const { current: vuetifyLocale } = useLocale();
  const switchLocalePath = useSwitchLocalePath();
  const { notify } = useNotification();

  const regions = [
    {
      name: "Česká republika",
      iso2: "CZ",
      language: "cs",
      locales: "cs-CZ",
      currency: "CZK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    {
      name: "Slovensko",
      iso2: "SK",
      language: "sk",
      locales: "sk-SK",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }
  ];

  const regionLanguageMap = {
    cz: ["cs", "en"],
    sk: ["sk", "en"]
  };

  const availableLocales = computed(() => {
    const region = runtimeConfig.public.region as keyof typeof regionLanguageMap;
    const regionLanguages: string[] = region && region in regionLanguageMap
      ? regionLanguageMap[region]
      : [];
    return (locales.value).filter(i =>
      (
        (regionLanguages.length && regionLanguages.includes(i.code as string)) ||
      !regionLanguages.length
      ) &&
    (i.code as string) !== locale.value
    );
  });

  const defaultLanguage = computed(() => {
    const region = runtimeConfig.public.region as keyof typeof regionLanguageMap;
    return region && region in regionLanguageMap
      ? regionLanguageMap[region][0]
      : "en";
  });

  async function changeLanguage (lang_: string): Promise<void> {
    const isValidLocale = availableLocales.value.some(locale_ => locale_.code === lang_);
    const lang = isValidLocale ? lang_ : defaultLanguage.value;
    await setLocale(lang as Locale);
    if (import.meta.client && window?.cookieBar) {
      window?.cookieBar?.changeLanguage(lang);
    }
    vuetifyLocale.value = lang;
    navigateTo(switchLocalePath(lang as Locale), { replace: true });
    if (authStore.isLoggedIn) {
      const { error } = await api.call(api.commonUrls.auth.CHANGE_LANGUAGE(lang), {
        method: "POST"
      });
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: error.value.data?.detail ?? "",
          type: "error"
        });
      } else if (authStore.data) {
        authStore.data.language = lang;
      }
    }
  }

  return {
    regions,
    availableLocales,
    defaultLanguage,
    changeLanguage
  };
});
